var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1818ccb9728dddb05390a330a4196e738b451234"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { accountPagePath } from 'constants/url'
import { APP_ENV } from 'enum/app-env'
import {
    SENTRY_DSN,
    EXCLUDED_ENVIRONMENTS,
    FULL_CAPTURE_SAMPLE_RATE,
    HIGH_PRIORITY_SAMPLE_RATE,
    MED_PRIORITY_SAMPLE_RATE,
    LOW_PRIORITY_SAMPLE_RATE,
} from 'sentry-variables'

if (EXCLUDED_ENVIRONMENTS.indexOf(process.env.APP_ENV) === -1) {
    Sentry.init({
        dsn: SENTRY_DSN,
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampler: (samplingContext) => {
            // Examine provided context data (including parent decision, if any) along
            // with anything in the global namespace to compute the sample rate or
            // sampling decision for this transaction

            if (samplingContext.location.pathname.includes(accountPagePath)) {
                // These are important - take all data in production
                return process.env.APP_ENV === APP_ENV.PRODUCTION ? FULL_CAPTURE_SAMPLE_RATE : LOW_PRIORITY_SAMPLE_RATE
            } else if (samplingContext.location.pathname.includes('/flow')) {
                // These are important - take all data in production
                return process.env.APP_ENV === APP_ENV.PRODUCTION ? FULL_CAPTURE_SAMPLE_RATE : LOW_PRIORITY_SAMPLE_RATE
            } else if (samplingContext.location.pathname === '/') {
                // capture more events on homepage as almost everyone visits this page
                return process.env.APP_ENV === APP_ENV.PRODUCTION ? LOW_PRIORITY_SAMPLE_RATE : HIGH_PRIORITY_SAMPLE_RATE
            } else {
                return process.env.APP_ENV === APP_ENV.PRODUCTION ? MED_PRIORITY_SAMPLE_RATE : LOW_PRIORITY_SAMPLE_RATE
            }
        },
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
        environment: process.env.APP_ENV,
        // todo: this is a temp solution to reduce total events
        // targeting this event
        ignoreErrors: ['ResizeObserver', 'get_active_memberships', 'heap.push is not a function'],
    })
}
